import React from "react";
import AdSense from 'react-adsense'
import styled from '@emotion/styled'

const StyledAd = styled.footer`
  max-width: ${props => props.theme.maxWidth};
  margin: 0rem auto 0 auto;
  padding: 2rem;
  color: ${props => props.theme.colors.grey};
`
const Ad = () => (
  <StyledAd>
      <AdSense.Google
        client="ca-pub-6472136014553171"
        slot="3440133207"
        format="auto"
	responsive='true'
        />
    </StyledAd>
  )
export default Ad;
